<template>
  <PxHeader :activeSearchMobile="false" />
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else class="bg-white sizeMinAllView">
    <!-- BOTON FLOTANTE PUBLICAR FINCA  -->
    <publish-button />
    <div
      class="pt-7 pb-8 flex flex-col items-center justify-center md:pt-12 md:pb-12"
    >
      <img
        v-if="record"
        :src="loadImg(record.img_head_url)"
        srcset="@/assets/images/logopoliticasdeprivacidad@2x.png"
        alt=""
        class="w-16 mb-2 md:w-24 md:mb-3"
      />
      <p
        class="font-nexa font-regular text-agrogo-green-2 text-base md:text-2xl style-info"
        v-if="record"
        v-html="record.title"
      ></p>
    </div>
    <div class="bg-agrogo-green-2">
      <div class="w-full h-2 bg-agrogo-green-2 md:hidden"></div>
      <div class="w-full h-3 bg-agrogo-green-3 md:h-8"></div>
      <div class="h-auto relative">
        <div
          class="flex w-1/2 bg-agrogo-yellow-1 h-3 md:h-8 absolute -top-1 right-0"
        ></div>
      </div>
      <div
        class="bg-white pb-6 md:mx-16 lg:mx-24 xl:mx-44 md:pb-11 padding-top"
      >
        <div class="container-privacypolicy scroll-color-step7">
          <div class="h-full" v-if="record">
            <div v-for="item in record.page_content" :key="item.id">
              <div
                class="font-dinpro font-normal text-sm text-agrogo-black-1 text-left space-y-10 md:text-base"
                v-html="item.content"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import PxHeader from "@/components/header/PxHeader";
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
import PublishButton from "@/components/publishButton/PublishButton";

export default {
  name: "PrivacyPolicy",
  inject: ["provider"],
  components: {
    PxHeader,
    PublishButton,
  },
  data() {
    return {
      /**
       * Variable that allows access to the methods of the provider publishFarmRepository
       * @type {Object}
       */
      repository: this.provider.publishFarmRepository,
      /**
       * Variable that saves the data of the questions obtained by the agrogo api
       * @type {Array}
       */
      record: null,
      /**
       * Variable that enables or disables the loading gif
       * @type {Boolean}
       */
      isBusy: false,
    };
  },
  methods: {
    /**
     * Query the agrogo api and obtain the information of the Terms and Condition.
     * @returns  {void}
     */
    async getTermsCondition() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllNames(
          "AUTHORIZATION_PERSONAL_DATA",
          dataAditional
        );
        me.record = data;
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
        window.scroll(0, 0);
      }
    },
    /**
     * get the full amazon s3 url to be rendered in the img tag.
     * @param {String} urlImg  receives the part to complete of the image url
     * @returns  {String} Returns the full url of the image on the server
     */
    loadImg(urlImg) {
      let url = `${API_ROUTES.image.get}/${urlImg}`;
      return url;
    },
  },
  mounted() {
    const me = this;
    me.getTermsCondition();
  },
};
</script>

<style>
.padding-top {
  padding-top: 72px;
}
</style>
